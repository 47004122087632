<template>
  <section id="target-audience" class="fade-in section unified-section">
    <div class="container unified-content">
      <p class="english-title">Target Audience</p>
      <h2 class="section-title">適合人群</h2>
      <div class="grid target-audience-grid">
        <div class="card target-audience-card">
          <img src="https://saas-2-ryx.oss-cn-guangzhou.aliyuncs.com/1522186891129004033/283f9d2441a74cdc87f04928547f0f1d.WEBP" alt="Children from Mainland China" class="target-audience-image" />
          <p class="english-title">Children from Mainland China</p>
          <h3 class="card-title">雙非港寶</h3>
          <p class="card-description">一直在內地讀書，缺乏節奏和學習規劃。<br> <span class="english-text">Children from Mainland China lacking rhythm and learning plans.</span></p>
        </div>
        <div class="card target-audience-card">
          <img src="https://saas-2-ryx.oss-cn-guangzhou.aliyuncs.com/1522186891129004033/aaf07e5a040e4eda945260e7a2c614c7.WEBP" alt="New Immigrants' Children" class="target-audience-image" />
          <p class="english-title">New Immigrants' Children</p>
          <h3 class="card-title">新港人孩子</h3>
          <p class="card-description">剛拿到香港身份，渴望優質教育資源。<br> <span class="english-text">New immigrants' children seeking quality educational resources.</span></p>
        </div>
        <div class="card target-audience-card">
          <img src="https://saas-2-ryx.oss-cn-guangzhou.aliyuncs.com/1522186891129004033/86015603ca64421b8c090d8d802887a5.WEBP" alt="Local Students" class="target-audience-image" />
          <p class="english-title">Local Students</p>
          <h3 class="card-title">香港本地生</h3>
          <p class="card-description">基礎薄弱，立志衝刺更好的學校。<br> <span class="english-text">Local students with a weak foundation aiming for better schools.</span></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      showTopBar: true,
      menuVisible: false
    };
  },
  methods: {
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    closeMenu(event) {
      if (this.menuVisible && !this.$refs.menu.contains(event.target) && !event.target.closest('.top-bar-more')) {
        this.menuVisible = false;
      }
    },
    beforeEnter(el) {
      el.style.opacity = 0;
    },
    enter(el, done) {
      el.offsetHeight; // trigger reflow
      el.style.transition = 'opacity 0.5s';
      el.style.opacity = 1;
      done();
    },
    leave(el, done) {
      el.style.transition = 'opacity 0.5s';
      el.style.opacity = 0;
      done();
    }
  },
  mounted() {
    document.addEventListener('click', this.closeMenu);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeMenu);
  }
};
</script>

<style scoped>

/* 样式设置 */
.unified-content {
  max-width: 75%;
  margin: 0 auto;
}


/* Section Styles */
.section {
  padding: 40px 0;

  opacity: 0;
  transition: opacity 1s ease-out;
}
.unified-section {
  background-color: #f0f0f0;
}
section {

  color: #333;
  opacity: 0;
  transition: opacity 1s ease-out;

}

.section-title {
  font-size: 28px;
  color: #004d40;
}
.english-title {
  font-size: 20px;
  color: #00796b;
}

.grid {
  display: flex;
  flex-wrap: wrap;
}
.target-audience-grid {
  gap: 20px;
}
.target-audience-card {
  flex: 1 1 30%;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 5px;
}
.card-title {
  font-size: 18px;
  color: #00796b;
}
.card-description {
  font-size: 14px;
}

.overview-column:hover .overview-image {
  opacity: 0.8;
}


.target-audience-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
}


.section-title {
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: bold;
}


.service-icon img {
  width: 50px;
  height: 50px;
}

#approved-cases .container {
  margin: 0 auto;
  padding: 0 15px;
}

#approved-cases .english-title {
  font-size: 18px;
  color: #333;
  text-align: start;
  margin-bottom: 10px;
}

#approved-cases .section-title {
  font-size: 28px;
  text-align: start;
  margin-bottom: 40px;
}

#approved-cases .approved-cases-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Space between items */
  justify-content: center;
}

#approved-cases .case-item {
  flex: 1 1 calc(33.333% - 20px); /* 3 items per row with gap */
  max-width: calc(33.333% - 20px); /* Max width for each item */
  box-sizing: border-box;
}

#approved-cases .case-item img {
  width: 100%;
  height: auto;
  border-radius: 8px; /* Rounded corners for images */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

#approved-cases .case-item .case-description {
  text-align: center;
  font-size: 16px;
  color: #666;
  margin-top: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  #approved-cases .case-item {
    flex: 1 1 calc(50% - 20px); /* 2 items per row on smaller screens */
    max-width: calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  #approved-cases .case-item {
    flex: 1 1 100%; /* 1 item per row on very small screens */
    max-width: 100%;
  }
}
.target-audience-card, .case-item {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.target-audience-card:hover, .case-item:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


.section-title, .service-title {
  transition: color 0.3s ease, text-shadow 0.3s ease;
}

.section-title:hover, .service-title:hover {
  color: #00796b;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}


.top-bar a {
  color: #ffffff;
  text-decoration: none;
  margin: 0 15px;
  position: relative;
  transition: color 0.3s ease;
}

.top-bar a:hover {
  color: #ffeb3b;
}

.top-bar .indicator {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 0;
  background-color: #ffeb3b;
  transition: width 0.3s ease;
}

.top-bar a:hover ~ .indicator {
  width: 100%;
}

section.visible {
  opacity: 1;
}

.text-overlay p {
  margin-bottom: 15px;
  line-height: 1.8;
  font-size: 16px;
}


@media (max-width: 768px) {
  .text-overlay {
    width: 95%;
    padding: 15px;
  }

  .text-overlay p {
    font-size: 14px;
  }
}



.text-content h1 {
  font-size: 24px;
  color: #333;
  margin-bottom: 15px;
}

.text-content p {
  font-size: 18px;
  color: #555;
  line-height: 1.6;
  margin: 10px 0;
}


.text-content h2 {
  font-size: 1.5em;
  color: #00796b;
  margin-bottom: 10px;
  font-weight: bold;
}

.text-content p {
  font-size: 1em;
  line-height: 1.6;
}

footer {
  text-align: center;
  margin-top: 30px;
  font-size: 0.8em;
  color: #777;
}


@keyframes fadeInUp {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* 气泡动画 */
@keyframes bubble {
  0% {
    transform: translateY(0) translateX(0) scale(1);
    opacity: 1;
  }
  50% {
    transform: translateY(-50vh) translateX(20px) scale(1.2);
    opacity: 0.7;
  }
  100% {
    transform: translateY(-100vh) translateX(-20px) scale(1);
    opacity: 0;
  }
}


.image-container img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.image-container img:hover {
  transform: scale(1.05);
}

h1 {
  font-size: 1.8em;
  color: #333;
  margin-bottom: 15px;
  text-align: center;
}


ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin-bottom: 10px;
}

p {
  text-align: justify;
}

@media (max-width: 768px) {
  h1 {
    font-size: 1.5em;
  }
}
</style>
