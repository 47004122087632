<template>
  <section id="about" class="fade-in section unified-section">
    <div class="container unified-content">
      <p class="english-title">About Us</p>
      <h2 class="section-title">關於我們</h2>
      <div class="about-content">
        <div class="company-intro">
          <video
              ref="videoPlayer"
              src="https://ryximages.ryx365.com/jlzx/jlzx-video.mp4"
              controls
              loop
              autoplay
              muted
              playsinline
              preload="auto"
              class="about-video"
          >
            <source src="https://ryximages.ryx365.com/jlzx/jlzx-video-480p.mp4" type="video/mp4" media="(max-width: 768px)">
            <source src="https://ryximages.ryx365.com/jlzx/jlzx-video.mp4" type="video/mp4">
          </video>

          <div class="text-overlay">
            <p>
              <span class="highlight">深圳市佳力信息諮詢有限公司</span>（簡稱「佳力諮詢」）是一站式港校升學服務引航者，目前於深圳及香港設立雙總部，並在瑞士、北京、上海、成都、濟南等地設有分公司與業務部。憑藉多年集團母公司服務金融高淨值客戶的豐富經驗，專注於連接內地、香港及國際市場。

              佳力諮詢在香港灣仔及尖沙咀均設有辦事處，致力於為每一個赴港家庭提供涵蓋幼稚園、小學、中學、本科、碩士、冬夏令營的一站式愛心擇校服務，助力實現<span class="highlight">夢想與美好未來</span>。

              母公司融易學成立於2015年，總部位於深圳，是金融行業領軍者之一、國家高新技術企業，以及深圳市專精特新中小企業。2024年獲得千萬級A+輪融資，由國務院新聞辦管理的中國網傳媒戰略投資。

              佳力核心業務包括：

              香港升學規劃
              全球留學教育
              全球移民
              在香港一站式升學服務方面，佳力已累積<span class="highlight">1000+</span>成功<span class="highlight">Offer</span>的實戰經驗，幫助<span class="highlight">600+</span>新港家庭享受高品質國際教育，客戶遍及<span class="highlight">300個城市</span>。無論是<span class="highlight">「優才計劃」</span>、<span class="highlight">「專才計劃」</span>，還是<span class="highlight">「留學計劃」</span>、<span class="highlight">「企業家計劃」</span>的申請成功案例，佳力的獲批率在行業內位居前列。

              透過全球視野、雄厚實力、專業團隊、服務體系、豐富案例與良好口碑，佳力的留學移民諮詢板塊為新中產家庭提供一站式香港身份或留學教育規劃服務，以及全球移民業務，成為極具專業性與全球影響力的國際移民留學顧問。            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutSection",
  mounted() {
    this.setupAutoplay();
  },
  methods: {
    setupAutoplay() {
      const video = this.$refs.videoPlayer;

      // 在微信中触发自动播放
      document.addEventListener('WeixinJSBridgeReady', function () {
        video.play();
      }, false);

      // 确保视频在页面加载时自动播放
      video.play().catch(() => {
        // 如果自动播放被阻止，则在用户交互时再播放
        video.addEventListener('click', () => {
          video.play();
        });
      });
    }
  }
};
</script>

<style scoped>
.section {
}

.unified-section {
  background-color: #f5f5f5;
  text-align: start;
}

.unified-content {
  margin: 0 auto;
}

.section-title {
  font-size: 24px;
  color: #004d40;
  text-align: center;
  margin-bottom: 10px;
}

.english-title {
  font-size: 18px;
  color: #00796b;
  text-align: center;
  margin-bottom: 20px;
}

.about-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.company-intro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.text-overlay {
  text-align: left;
}

.highlight {
  color: #3fb3a6;
  font-weight: bold;
}

.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-in-enter-active, .fade-in-leave-active {
  opacity: 1;
}

.about-content {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.about-content:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.company-intro {
  position: relative;
  text-align: left;
}

.about-video {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  filter: brightness(90%);
}

.text-overlay {
  border-radius: 10px;
  line-height: 1.8;
  font-size: 16px;
}

/* 确保在移动端上视频自动播放且响应式 */
@media (max-width: 768px) {
  .about-content {
    flex-direction: column;
  }

  .about-video {
    width: 100%;
    height: auto;
  }
}
</style>
