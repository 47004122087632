<template>
  <section id="target-audience" class="fade-in section unified-section">
    <div class="container unified-content">
      <p class="english-title">Target Audience</p>
      <h2 class="section-title">適合人群</h2>
      <div class="grid target-audience-grid">
        <div class="card target-audience-card">
          <img src="https://saas-2-ryx.oss-cn-guangzhou.aliyuncs.com/1522186891129004033/283f9d2441a74cdc87f04928547f0f1d.WEBP" alt="Children from Mainland China" class="target-audience-image" />
          <p class="english-title">Children from Mainland China</p>
          <h3 class="card-title">雙非港寶</h3>
          <p class="card-description">一直在內地讀書，缺乏節奏和學習規劃。<br> <span class="english-text">Children from Mainland China lacking rhythm and learning plans.</span></p>
        </div>
        <div class="card target-audience-card">
          <img src="https://saas-2-ryx.oss-cn-guangzhou.aliyuncs.com/1522186891129004033/aaf07e5a040e4eda945260e7a2c614c7.WEBP" alt="New Immigrants' Children" class="target-audience-image" />
          <p class="english-title">New Immigrants' Children</p>
          <h3 class="card-title">新港人孩子</h3>
          <p class="card-description">剛拿到香港身份，渴望優質教育資源。<br> <span class="english-text">New immigrants' children seeking quality educational resources.</span></p>
        </div>
        <div class="card target-audience-card">
          <img src="https://saas-2-ryx.oss-cn-guangzhou.aliyuncs.com/1522186891129004033/86015603ca64421b8c090d8d802887a5.WEBP" alt="Local Students" class="target-audience-image" />
          <p class="english-title">Local Students</p>
          <h3 class="card-title">香港本地生</h3>
          <p class="card-description">基礎薄弱，立志衝刺更好的學校。<br> <span class="english-text">Local students with a weak foundation aiming for better schools.</span></p>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
export default {
  data() {
    return {
      showTopBar: true,
      menuVisible: false
    };
  },
  methods: {
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    closeMenu(event) {
      if (this.menuVisible && !this.$refs.menu.contains(event.target) && !event.target.closest('.top-bar-more')) {
        this.menuVisible = false;
      }
    },
    beforeEnter(el) {
      el.style.opacity = 0;
    },
    enter(el, done) {
      el.offsetHeight; // trigger reflow
      el.style.transition = 'opacity 0.5s';
      el.style.opacity = 1;
      done();
    },
    leave(el, done) {
      el.style.transition = 'opacity 0.5s';
      el.style.opacity = 0;
      done();
    }
  },
  mounted() {
    document.addEventListener('click', this.closeMenu);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeMenu);
  }
};
</script>

<style scoped>
./* 样式设置 */
.unified-content {
  max-width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
}

/* Section Styles */
.section {
  padding: 20px 0;
  background-color: #f0f0f0;
}

.unified-section {
  background-color: #f0f0f0;
}

.section-title {
  font-size: 24px;
  color: #004d40;
  text-align: center;
  margin-bottom: 10px;
}

.english-title {
  font-size: 18px;
  color: #00796b;
  text-align: center;
  margin-bottom: 20px;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
}

.target-audience-card {
  flex: 1 1 100%;
  max-width: 100%;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.target-audience-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.card-title {
  font-size: 16px;
  color: #00796b;
  margin-top: 10px;
}

.card-description {
  font-size: 14px;
  color: #666;
  margin-top: 10px;
}

.english-text {
  display: block;
  font-size: 12px;
  color: #00796b;
}

@media (min-width: 768px) {
  .target-audience-card {
    flex: 1 1 calc(50% - 15px); /* 2 items per row on tablets */
    max-width: calc(50% - 15px);
  }
}

@media (min-width: 1024px) {
  .target-audience-card {
    flex: 1 1 calc(33.333% - 15px); /* 3 items per row on desktops */
    max-width: calc(33.333% - 15px);
  }
}

</style>
