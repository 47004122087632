<template>
  <transition name="fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
    <div class="top-bar" v-show="showTopBar">
      <div class="container top-bar-content">
        <img src="https://saas-2-ryx.oss-cn-guangzhou.aliyuncs.com/1522186891129004033/78033e7447bb4de6b626c02559f66d96.png" alt="Logo" class="top-bar-logo" />
        <button class="top-bar-more" @click="toggleMenu">更多</button>
        <div class="top-bar-menu" v-show="menuVisible" ref="menu">
          <a href="/#home" class="top-bar-link">首頁</a>
          <a href="/#about" class="top-bar-link">關於我們</a>
          <a href="/#target-audience" class="top-bar-link">適合人群</a>
          <a href="/#hong-kong-school-tuition" class="top-bar-link">香港學校學費</a>
          <a href="/#services" class="top-bar-link">擇校服務</a>
          <router-link to="/success-cases" class="top-bar-link">成功案例</router-link>
          <a href="/#contact" class="top-bar-link">聯絡我們</a>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      showTopBar: true,
      menuVisible: false
    };
  },
  methods: {
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    closeMenu(event) {
      if (this.menuVisible && !this.$refs.menu.contains(event.target) && !event.target.closest('.top-bar-more')) {
        this.menuVisible = false;
      }
    },
    beforeEnter(el) {
      el.style.opacity = 0;
    },
    enter(el, done) {
      el.offsetHeight; // trigger reflow
      el.style.transition = 'opacity 0.5s';
      el.style.opacity = 1;
      done();
    },
    leave(el, done) {
      el.style.transition = 'opacity 0.5s';
      el.style.opacity = 0;
      done();
    }
  },
  mounted() {
    document.addEventListener('click', this.closeMenu);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeMenu);
  }
};
</script>

<style scoped>
.top-bar {
  width: 100%;
  background-color: #00796b;
  border-bottom: 1px solid #004d40;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 10px 0;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 20px 0 20px;
}

.top-bar-logo {
  height: 40px;
}

.top-bar-more {
  background: none;
  border: none;
  font-size: 30px;
  color: #ffffff;
  cursor: pointer;
}

.top-bar-menu {
  position: absolute;
  top: 60px;
  right: 15px;
  background: #ffffff;
  border: 1px solid #00796b;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 200px;
}

.top-bar-link {
  display: block;
  color: #00796b;
  padding: 8px 10px;
  text-decoration: none;
}

.top-bar-link:hover {
  background-color: #e0f2f1;
  color: #004d40;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

@media (max-width: 600px) {
  .top-bar-logo {
    height: 50px;
  }

  .top-bar-more {
    font-size: 20px;
  }

  .top-bar-menu {
    top: 50px;
    right: 10px;
    width: 180px;
  }

  .top-bar-link {
    padding: 6px 8px;
  }
}
</style>
