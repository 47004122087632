import { createRouter, createWebHistory } from 'vue-router';
import Home from '../view/home.vue'
import SuccessCases from '../view/success-cases/index.vue'


const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/success-cases',
        name: 'successCases',
        component: SuccessCases,
    },

];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
