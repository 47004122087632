<template>
  <div>

    <!-- Header -->
    <HeaderMobile v-if="isMobile"/>
    <HeaderPC v-if="!isMobile"/>
    <!-- Main Content -->
    <main >
      <div class="bubble-container">
        <div class="bubble" v-for="(bubble, index) in bubbles" :key="index" :style="getBubbleStyle(index)"></div>
      </div>
      <!--PC About Section -->
      <AboutSectionMobile v-if="isMobile"/>
      <AboutSectionPC v-if="!isMobile"/>
      <!-- Target Audience Section -->
      <TargetAudienceSectionMobile v-if="isMobile"/>
      <TargetAudienceSectionPC v-if="!isMobile"/>

      <!-- Services Section -->
      <section id="services" class="fade-in section unified-section">
        <div class="container unified-content">
          <p class="english-title">Our Services</p>
          <h2 class="section-title">擇校服務內容</h2>
          <div class="services-wrapper">
            <ul class="services-list">
              <li class="services-item">

                <div class="service-text">
                  <h3 class="service-title">全面評估</h3>
                  <p class="service-description">深入了解學生的學習情況、興趣愛好、性格特點以及家庭需求，為每位學生制定個性化的擇校方案。</p>
                </div>
              </li>
              <li class="services-item">
                <div class="service-text">
                  <h3 class="service-title">學校推薦</h3>
                  <p class="service-description">根據評估結果，精準推薦適合學生的多所學校，並詳細介紹學校的優勢、特色和入學要求。</p>
                </div>
              </li>
              <li class="services-item">
                <div class="service-text">
                  <h3 class="service-title">申請協助</h3>
                  <p class="service-description">幫助家長準備申請材料，確保材料齊全、準確、符合學校要求。</p>
                </div>
              </li>
              <li class="services-item">
                <div class="service-text">
                  <h3 class="service-title">課程輔導</h3>
                  <p class="service-description">針對香港學校的課程設置，為學生提供專業的學科輔導，幫助他們順利銜接和適應新的學習內容。</p>
                </div>
              </li>
              <li class="services-item">
                <div class="service-text">
                  <h3 class="service-title">面試培訓</h3>
                  <p class="service-description">提供專業的面試技巧培訓，包括禮儀、表達、應對策略等，讓學生在面試中展現出最佳狀態。</p>
                </div>
              </li>
              <li class="services-item">
                <div class="service-text">
                  <h3 class="service-title">跟蹤服務</h3>
                  <p class="service-description">在學生入學後，持續跟蹤其學習和適應情況，及時提供指導和建議。</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <!-- Success Cases Section -->
<!--      <SuccessCasesMobile v-if="isMobile"/>-->
<!--      <SuccessCasesPC v-if="!isMobile"/>-->


      <section id="hong-kong-school-tuition" class="fade-in section unified-section">
        <div class="container unified-content">
          <p class="english-title">Hong Kong school tuition</p>
          <h2 class="section-title">香港學校學費</h2>
          <div class="overview-columns">
            <div class="primary-schools">
              <div class="fee-info">
                <div class="fee-item" v-for="(item, index) in feeItems" :key="index">
                  <div class="image-wrapper">
                    <img :src="item.imgSrc" :alt="item.title" class="fee-image" />
                  </div>
                  <div class="text-content">
                    <h2>{{ item.title }}</h2>
                    <p>{{ item.description }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section id="admissions-certificates-school1" class="fade-in section unified-section">
        <div class="container unified-content">
          <p class="english-title">Hong Kong School Admission System</p>
          <h2 class="section-title">香港升學制度</h2>
          <div class="overview-columns">
            <div class="grid-container">
              <div class="grid-item">
                <div class="content">
                  <h1>香港升學制度</h1>
                  <p>香港學制涵蓋幼稚園至中學六年級，與國際多種學制相對接，如<b class="highlight">英國</b>、<b class="highlight">美國</b>、<b class="highlight">加拿大</b>和<b class="highlight">新加坡</b>等。</p>
                </div>
                <div class="image-container">
                  <img src="https://saas-2-ryx.oss-cn-guangzhou.aliyuncs.com/1522186891129004033/6c87b509f93c419d9391a60a3f4a0589.jpg" alt="香港升學制度">
                </div>
              </div>

              <div class="grid-item">
                <div class="image-container">
                  <img src="https://saas-2-ryx.oss-cn-guangzhou.aliyuncs.com/1522186891129004033/88abde3cb3df4e0bbf09db397565fd4e.jpg" alt="入學申請時間線">
                </div>
                <div class="content">
                  <h1>入學申請時間線</h1>
                  <ul>
                    <li><b class="highlight">小學插班：</b> 基本只接受小二至小五年級插班。</li>
                    <li><b class="highlight">中學插班：</b> 基本只接受中二至中五年級插班。</li>
                  </ul>
                </div>
              </div>

              <div class="grid-item">
                <div class="content">
                  <h1>學校特色</h1>
                  <ul>
                    <li><b class="highlight">男校、女校、男女校：</b> 男校優勢學科偏向理工和體育，女校偏向文科和藝術。男校注重培養領導力和責任感，女校著重培養淑女氣質。</li>
                    <li><b class="highlight">英文中學與中文中學：</b> 英文中學大部分科目採用英文教材，中文中學則主要使用中文教材。</li>
                  </ul>
                </div>
                <div class="image-container">
                  <img src="https://saas-2-ryx.oss-cn-guangzhou.aliyuncs.com/1522186891129004033/4b12dc3951b44b3a9076bcd3cb223b75.jpg" alt="學校特色">
                </div>
              </div>

              <div class="grid-item">
                <div class="image-container">
                  <img src="https://saas-2-ryx.oss-cn-guangzhou.aliyuncs.com/1522186891129004033/631503896e6e4356b5d74e32359e4cb4.jpg" alt="中學Banding劃分">
                </div>
                <div class="content">
                  <h1>中學Banding劃分</h1>
                  <p>根據小學呈分試成績，將學生分為<b class="highlight">Band 1</b>、<b class="highlight">Band 2</b>和<b class="highlight">Band 3</b>，每組占全港升中人數的33.33%。</p>
                </div>
              </div>

              <div class="grid-item">
                <div class="content">
                  <h1>教會與非教會學校</h1>
                  <p>香港傳統名校多由基督教或天主教教會創立，但非信徒同樣可以入讀。若對宗教有特別要求或抗拒，可選擇非教會學校。</p>
                </div>
                <div class="image-container">
                  <img src="https://saas-2-ryx.oss-cn-guangzhou.aliyuncs.com/1522186891129004033/665cc55457cf4919b2119d17be9d30be.jpg" alt="教會與非教會學校">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <!-- 新增获批案例板块 -->
      <!-- Add New Section for Approved Cases Here -->
<!--      <section id="approved-cases" class="fade-in section unified-section">-->
<!--        <div class="container unified-content">-->
<!--          <p class="english-title">Approved Cases</p>-->
<!--          <h2 class="section-title">獲批案例</h2>-->
<!--          <div class="approved-cases-grid">-->
<!--            <div class="case-item">-->
<!--              <img src="https://saas-2-ryx.oss-cn-guangzhou.aliyuncs.com/1522186891129004033/fdf0039a09bb421c989fac61328f5269.jpg" alt="Approved Case 1">-->
<!--            </div>-->
<!--            <div class="case-item">-->
<!--              <img src="https://saas-2-ryx.oss-cn-guangzhou.aliyuncs.com/1522186891129004033/f41723aa11b94ac9b15f78f8cb2a07a5.jpg" alt="Approved Case 2">-->
<!--            </div>-->
<!--            <div class="case-item">-->
<!--              <img src="https://saas-2-ryx.oss-cn-guangzhou.aliyuncs.com/1522186891129004033/178caf5f95a84cf7a71c3d55f9d6c025.jpg" alt="Approved Case 3">-->
<!--            </div>-->
<!--            <div class="case-item">-->
<!--              <img src="https://saas-2-ryx.oss-cn-guangzhou.aliyuncs.com/1522186891129004033/c46218eb9b7e42789239c7eebfd9c741.jpg" alt="Approved Case 4">-->
<!--            </div>-->
<!--            <div class="case-item">-->
<!--              <img src="https://saas-2-ryx.oss-cn-guangzhou.aliyuncs.com/1522186891129004033/e0d052532e0548f2adf1402e3a7a2e1e.jpg" alt="Approved Case 5">-->
<!--            </div>-->

<!--          </div>-->
<!--        </div>-->
<!--      </section>-->

      <!-- Application Process Section -->
      <section id="application-process" class="fade-in section unified-section">
        <div class="container unified-content">
          <p class="english-title" >Application Process</p>
          <h2 class="section-title" >申請流程</h2>
          <div class="application-steps">
            <div class="step">
              <div class="step-icon">1</div>
              <div class="step-content">
                <h3 class="step-title">聯絡我們</h3>
                <p class="step-description">填寫聯絡表單或致電我們的客服，了解更多服務詳情。</p>
              </div>
            </div>
            <div class="arrow-icon">&#9660;</div>
            <div class="step">
              <div class="step-icon">2</div>
              <div class="step-content">
                <h3 class="step-title">進行評估</h3>
                <p class="step-description">我們會與您安排一次詳細的面談，評估學生的需求。</p>
              </div>
            </div>
            <div class="arrow-icon">&#9660;</div>
            <div class="step">
              <div class="step-icon">3</div>
              <div class="step-content">
                <h3 class="step-title">推薦學校</h3>
                <p class="step-description">根據評估結果，我們會為您提供幾所合適的學校選擇。</p>
              </div>
            </div>
            <div class="arrow-icon">&#9660;</div>
            <div class="step">
              <div class="step-icon">4</div>
              <div class="step-content">
                <h3 class="step-title">協助申請</h3>
                <p class="step-description">協助您準備申請材料並提交到選擇的學校。</p>
              </div>
            </div>
            <div class="arrow-icon">&#9660;</div>
            <div class="step">
              <div class="step-icon">5</div>
              <div class="step-content">
                <h3 class="step-title">跟蹤進度</h3>
                <p class="step-description">在申請過程中，我們會持續跟蹤進度，提供幫助。</p>
              </div>
            </div>
          </div>
        </div>
      </section>


      <!-- Contact Section -->
      <section id="contact" class="fade-in section unified-section">
        <div class="container unified-content">
          <p class="english-title">Contact Us</p>
          <h2 class="section-title">聯絡我們</h2>
          <p class="contact-info">如有任何問題或需要進一步了解我們的服務，請隨時聯絡我們：</p>
          <ul class="contact-details">
            <li>電話：
              <a :href="`tel:075525320861`" class="dial-button">
                撥打 075525320861
              </a>
            </li>
            <li>We Chat（微信）：lizhuoru99</li>
            <li>地址：
              <ul>
                <li>佳力香港國際教育中心：香港灣仔區告士打道60號（中國華融大廈）16樓</li>
                <li>佳力瑞士國際合作中心：瑞士蘇黎世/烏里州</li>
                <li>佳力諮詢香港分部：香港Moko新世紀廣場17樓</li>
                <li>佳力諮詢深圳總部：深圳市南山區百度國際大廈東塔樓32層</li>
                <li>佳力諮詢北京分部：北京市海淀區西三環北路中國外文大廈A座10層</li>
              </ul>
            </li>
          </ul>
        </div>
      </section>

    </main>


  </div>
</template>


<script>

import HeaderMobile from './../components/isMobile/HeaderMobile.vue';
import TargetAudienceSectionMobile from './../components/isMobile/TargetAudienceSectionMobile.vue';
import AboutSectionMobile from './../components/isMobile/AboutSectionMobile.vue';

import HeaderPC from './../components/pc/HeaderPC.vue';
import AboutSectionPC from './../components/pc/AboutSectionPC.vue';
import TargetAudienceSectionPC from './../components/pc/TargetAudienceSectionPC.vue';


export default {
  components:{
    HeaderMobile,
    TargetAudienceSectionMobile,
    AboutSectionMobile,
    TargetAudienceSectionPC,
    HeaderPC,
    AboutSectionPC,
  },
  name: 'App',
  data() {
    return {
      showLogo: true,
      isMobile: false,
      bubbles: Array(10).fill(null),  // 创建5个气泡
      text: "深圳市佳力信息咨询有限公司成立于2018年，凭借多年集团母公司金融高净值客户纵深的服务经验，专注内地和香港乃至国际市场的链接。我們專注於香港中小學擇校業務，不僅幫助家長和學生解決擇校難題，還為他們提供全方位的指導與支持。我們相信每一個孩子都應該擁有找到最適合自己學校的機會，從而開啟一段充滿啟發與成長的學習之旅。透過我們的專業服務，我們致力於確保每個家庭都能為孩子選擇到最理想的教育環境，讓他們在未來的學術道路上蓬勃發展  \n Shenzhen Jiali Information Consulting Co., Ltd. was established in 2018, leveraging the extensive service experience of its parent company in the financial sector for high-net-worth clients. The company focuses on connecting the Mainland China, Hong Kong, and international markets. We specialize in Hong Kong primary and secondary school selection services, not only helping parents and students resolve school selection challenges but also providing them with comprehensive guidance and support. We believe that every child deserves the opportunity to find the school that best suits them, thereby embarking on a journey of learning filled with inspiration and growth. Through our professional services, we are committed to ensuring that each family can choose the most ideal educational environment for their children, enabling them to thrive academically in the future.",
      textArray: [],
      isVisible: [],
      showTopBar: false,
      showHeader: false,
      showSections: false,
      feeItems: [
        {
          imgSrc: 'https://saas-2-ryx.oss-cn-guangzhou.aliyuncs.com/1522186891129004033/b9f861754b494bff8d5ca5d239627d87.jpg',
          title: '官立、津貼學校',
          description: '中小学免学费。这类学校由香港政府资助，为学生提供免费的教育机会。',
        },
        {
          imgSrc: 'https://saas-2-ryx.oss-cn-guangzhou.aliyuncs.com/1522186891129004033/83741330736d41119943cdec2c43f6ec.jpg',
          title: '直資學校',
          description: '学费范围从7,000港币至80,000港币不等，相当于约6,300人民币至73,600人民币。这类学校由私人资助，但接受政府资助，学费比私立学校相对较低。',
        },
        {
          imgSrc: 'https://saas-2-ryx.oss-cn-guangzhou.aliyuncs.com/1522186891129004033/ce1b03a7467f4a9b8897b3f25b1986c0.jpg',
          title: '私立學校',
          description: '学费范围从30,000港币至200,000港币不等，相当于约27,000人民币至180,000人民币。私立学校通常由私人机构完全资助，因此学费较高。',
        },
        {
          imgSrc: 'https://saas-2-ryx.oss-cn-guangzhou.aliyuncs.com/1522186891129004033/b4bdc88bc7fd4f4b88de725d8e851f67.jpg',
          title: '國際學校',
          description: '学费大约在150,000港币至200,000港币左右，相当于约135,000人民币至180,000人民币。这类学校通常提供国际课程，学费水平相对较高。',
        },
      ],
    };
  },
  created() {
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkIfMobile);
  },
  mounted() {
    document.title = '擇校，我們是您最優選擇';
    // 一秒钟后隐藏Logo并显示主要内容
    setTimeout(() => {
      this.showLogo = false;
    }, 1000);

    this.textArray = this.text.split('');
    this.isVisible = Array(this.textArray.length).fill(false);
    this.fadeInText();

    this.showTopBar = true;
    this.showHeader = true;
    // 使所有模块渐显
    this.$nextTick(() => {
      this.showSections = true;
    });
    this.initIntersectionObserver();

  },
  methods: {
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 600;
    },
    getBubbleStyle() {
      const size = 50 + Math.random() * 100; // 随机气泡大小
      const left = Math.random() * 100; // 随机气泡位置
      const duration = 6 + Math.random() * 4; // 随机动画持续时间

      return {
        width: `${size}px`,
        height: `${size}px`,
        left: `${left}%`,
        animationDuration: `${duration}s`,
        zIndex: -1  // 设置气泡的 z-index 为 1，使其在最底层
      };
    },
    fadeInText() {
      this.textArray.forEach((_, index) => {
        setTimeout(() => {
          this.isVisible[index] = true;
        }, index * 10); // 每个字符之间的延迟时间
      });
    },
    initIntersectionObserver() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          } else {
            entry.target.classList.remove('visible');
          }
        });
      }, options);

      document.querySelectorAll('.fade-in').forEach(element => {
        observer.observe(element);
      });
    },
    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = 'translateY(20px)';
    },
    enter(el, done) {
      el.offsetHeight; // trigger reflow
      el.style.transition = 'opacity 0.5s ease, transform 0.5s ease';
      el.style.opacity = 1;
      el.style.transform = 'translateY(0)';
      done();
    },
    leave(el, done) {
      el.style.transition = 'opacity 0.5s ease, transform 0.5s ease';
      el.style.opacity = 0;
      el.style.transform = 'translateY(20px)';
      done();
    }
  }
};

</script>

<style scoped>

/* 样式设置 */
.unified-content {
  max-width: 75%;
  margin: 0 auto;
}
/* Section Styles */
.section {
  padding: 40px 0;

  opacity: 0;
  transition: opacity 1s ease-out;
}
.unified-section {
  background-color: #f0f0f0;
}
section {

  color: #333;
  opacity: 0;
  transition: opacity 1s ease-out;

}

.section-title {
  font-size: 28px;
  color: #004d40;
}
.english-title {
  font-size: 20px;
  color: #00796b;
}


/* Overview Section */
.overview-columns {
  display: flex;
  justify-content: space-between;
  gap: 20px;

}

.overview-column:hover .overview-image {
  opacity: 0.8;
}


/* For mobile responsiveness */
@media (max-width: 768px) {
  .overview-columns {
    flex-direction: column;
  }
}
/* Services Section */
.services-list {
  list-style-type: none;
  padding: 0;
}
.services-item {
  margin-bottom: 15px;
}



/* Application Process Section */

.step-title {
  font-size: 18px;
  color: #00796b;
}
.step-description {
  font-size: 14px;
}

/* Contact Section */
.contact-info {
  font-size: 16px;
}
.contact-details {
  list-style-type: none;
  padding: 0;
}

/* Footer */
.footer {
  background-color: #00796b;
  color: #fff;
  text-align: center;
  padding: 20px 0;
}
.footer-text {
  margin: 0;
  text-align: center;
}

.services-list {
  list-style: none;
  padding: 0;
}

.services-item {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  background-color: #f9f9f9;
}

.service-text {
  max-width: 500px;
}

.service-title {
  margin-top: 0;
  font-size: 1.2rem;
  color: #00796b;
}

.service-description {
  margin: 10px 0 0;
  font-size: 1rem;
  color: #666;
}

.section-title {
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: bold;
}


.step-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #00796b;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  margin-right: 20px; /* 图标与内容的间距 */
}

.step-content {
  flex: 1;
}

.step-title {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

.step-description {
  font-size: 16px;
  margin: 5px 0 0;
}

.arrow-icon {
  font-size: 24px;
  color: #00796b;
  margin-left: 100px;
}
/* Services Section Styles */
.services-wrapper {
  /*display: flex;*/
  flex-wrap: wrap;
  gap: 20px;
}

.services-item {
  display: flex;
  align-items: flex-start;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  background: linear-gradient(135deg, #f0f9ff 0%, #00796b 100%);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.services-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
}

.service-icon img {
  width: 50px;
  height: 50px;
}

.service-text {
  flex-grow: 1;
}

.service-title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.service-description {
  font-size: 16px;
  color: #666;
}
/* Application Process Section Styles */
.application-steps {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step {
  display: flex;
  align-items: flex-start;
  background: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  transition: background 0.3s, box-shadow 0.3s;
}

.step:hover {
  background: #e9f5ff;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
}

.step-icon {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00796b;
  color: white;
  border-radius: 50%;
  font-size: 24px;
  font-weight: bold;
  margin-right: 15px;
}

.step-content {
  flex-grow: 1;
}

.step-title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.step-description {
  font-size: 16px;
  color: #666;
}

.arrow-icon {
  font-size: 24px;
  color: #00796b;
  margin: 0 15px;
}
/* Styles for Approved Cases Section */
#approved-cases {
}

#approved-cases .container {
  margin: 0 auto;
  padding: 0 15px;
}

#approved-cases .english-title {
  font-size: 18px;
  color: #333;
  text-align: start;
  margin-bottom: 10px;
}

#approved-cases .section-title {
  font-size: 28px;
  text-align: start;
  margin-bottom: 40px;
}

#approved-cases .approved-cases-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Space between items */
  justify-content: center;
}

#approved-cases .case-item {
  flex: 1 1 calc(33.333% - 20px); /* 3 items per row with gap */
  max-width: calc(33.333% - 20px); /* Max width for each item */
  box-sizing: border-box;
}

#approved-cases .case-item img {
  width: 100%;
  height: auto;
  border-radius: 8px; /* Rounded corners for images */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

#approved-cases .case-item .case-description {
  text-align: center;
  font-size: 16px;
  color: #666;
  margin-top: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  #approved-cases .case-item {
    flex: 1 1 calc(50% - 20px); /* 2 items per row on smaller screens */
    max-width: calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  #approved-cases .case-item {
    flex: 1 1 100%; /* 1 item per row on very small screens */
    max-width: 100%;
  }
}


.section-title, .service-title {
  transition: color 0.3s ease, text-shadow 0.3s ease;
}

.section-title:hover, .service-title:hover {
  color: #00796b;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}
.case-item {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.case-item:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.top-bar:hover {
  background-color: #00796b; /* 悬停时背景颜色 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 悬停时阴影效果 */
}

.top-bar a {
  color: #ffffff;
  text-decoration: none;
  margin: 0 15px;
  position: relative;
  transition: color 0.3s ease;
}

.top-bar a:hover {
  color: #ffeb3b;
}

.top-bar .indicator {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 0;
  background-color: #ffeb3b;
  transition: width 0.3s ease;
}

.top-bar a:hover ~ .indicator {
  width: 100%;
}

section.visible {
  opacity: 1;
}

.text-overlay p {
  margin-bottom: 15px;
  line-height: 1.8;
  font-size: 16px;
}


@media (max-width: 768px) {
  .text-overlay {
    width: 95%;
    padding: 15px;
  }

  .text-overlay p {
    font-size: 14px;
  }
}


.text-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}

.text-content h1 {
  font-size: 24px;
  color: #333;
  margin-bottom: 15px;
}

.text-content p {
  font-size: 18px;
  color: #555;
  line-height: 1.6;
  margin: 10px 0;
}




.fee-info {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.fee-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: calc(50% - 20px);
  transform: translateY(20px);
  opacity: 0;
  animation: fadeInUp 0.6s forwards;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.fee-item:hover {
  transform: translateY(0);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.image-wrapper {
  width: 40%;
  padding: 10px;
}

.fee-image {
  width: 80%;
  height: auto;
  object-fit: cover;
  border-radius: 4px;
}

.text-content {
  padding: 20px;
  flex: 1;
}

.text-content h2 {
  font-size: 1.5em;
  color: #00796b;
  margin-bottom: 10px;
  font-weight: bold;
}

.text-content p {
  font-size: 1em;
  line-height: 1.6;
}

footer {
  text-align: center;
  margin-top: 30px;
  font-size: 0.8em;
  color: #777;
}

@media (max-width: 768px) {
  .fee-item {
    flex-direction: column;
    width: 100%;
  }

  .image-wrapper {
    width: 100%;
    padding: 0;
  }

  .fee-image {
    width: 100%;
    border-radius: 0;
  }
}

@keyframes fadeInUp {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* 容器设置 */
.bubble-container {
  position: fixed;
  width: 100%;
  height: 100vh; /* 设置容器高度 */
  overflow: hidden; /* 隐藏溢出的气泡 */
}

/* 气泡基础样式 */
.bubble {
  position: fixed;
  bottom: -50px; /* 初始位置在容器底部外 */
  background-color: #8DBB744C; /* 半透明白色 */
  border-radius: 50%; /* 圆形气泡 */
  animation: bubble 10s infinite ease-in-out; /* 动画效果 */
}

/* 气泡动画 */
@keyframes bubble {
  0% {
    transform: translateY(0) translateX(0) scale(1);
    opacity: 1;
  }
  50% {
    transform: translateY(-50vh) translateX(20px) scale(1.2);
    opacity: 0.7;
  }
  100% {
    transform: translateY(-100vh) translateX(-20px) scale(1);
    opacity: 0;
  }
}


.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 20px;
}

.grid-item {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.grid-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.image-container {
  margin-top: 20px;
  width: 100%;
}

.image-container img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.image-container img:hover {
  transform: scale(1.05);
}

h1 {
  font-size: 1.8em;
  color: #333;
  margin-bottom: 15px;
  text-align: center;
}

.highlight {
  color: #27ab9d;
  font-weight: bold;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin-bottom: 10px;
}

p {
  text-align: justify;
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
  }

  h1 {
    font-size: 1.5em;
  }
}

</style>




