<template>
  <transition id="home" name="fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
    <header class="header" v-show="showHeader">
      <div class="header-background">
        <div class="container header-content">
          <h1 class="header-title">香港中小學擇校服務</h1>
          <p class="header-subtitle">
            我們專注於香港中小學擇校業務，幫助每個孩子找到最適合的學校。
          </p>
          <p class="header-subtitle-english">
            We specialize in school selection services for Hong Kong primary and secondary schools, helping each child find the most suitable school.
          </p>
          <a href="#contact" class="cta-button">聯絡我們</a>
        </div>
      </div>
    </header>
  </transition>
</template>

<script>
export default {
  name: "HeaderPC",
  data() {
    return {
      showHeader: true,
    };
  },
  methods: {
    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = 'translateY(20px)';
    },
    enter(el, done) {
      el.offsetHeight; // trigger reflow
      el.style.transition = 'opacity 0.5s ease, transform 0.5s ease';
      el.style.opacity = 1;
      el.style.transform = 'translateY(0)';
      done();
    },
    leave(el, done) {
      el.style.transition = 'opacity 0.5s ease, transform 0.5s ease';
      el.style.opacity = 0;
      el.style.transform = 'translateY(20px)';
      done();
    }
  },
};
</script>

<style scoped>
/* Header */
.header {
  display: flex;
  justify-content: center;
  align-items: center;


  position: relative;
  background-color: #333; /* 深色背景 */
  background-image: url('https://saas-2-ryx.oss-cn-guangzhou.aliyuncs.com/1522186891129004033/0190170bf51c449487fac3969be9d08f.jpg');


  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 93vh; /* 高度设置为100%视口高度 */
  overflow: hidden; /* 防止内容溢出 */
}
.header-background {
  background-size: cover;
  background-position: center;
}
.header-title {
  font-size: 36px;
  color: #ffffff;
  text-align: center;
}
.header-subtitle {
  font-size: 18px;
  color: #ffffff;
  text-align: center;
}
.header-subtitle-english {
  font-size: 16px;
  color: #ffffff;
  text-align: center;
}
.cta-button {
  display: block;
  width: 200px;
  margin: 20px auto;
  padding: 10px;
  background-color: #00796b;
  color: #fff;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
}

.header-content{
  padding: 20px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
</style>
