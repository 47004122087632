<template>
  <transition v-if="!isMobile" name="fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
    <div class="top-bar" v-show="showTopBar">
      <div class="container top-bar-content">
        <img
            src="https://saas-2-ryx.oss-cn-guangzhou.aliyuncs.com/1522186891129004033/78033e7447bb4de6b626c02559f66d96.png"
            alt="Logo"
            class="top-bar-logo"
        />
        <a href="/#home" class="top-bar-link">首頁</a>
        <a href="/#about" class="top-bar-link">關於我們</a>
        <a href="/#target-audience" class="top-bar-link">適合人群</a>
        <a href="/#hong-kong-school-tuition" class="top-bar-link">香港學校學費</a>
        <a href="/#services" class="top-bar-link">擇校服務</a>
        <router-link to="/success-cases" class="top-bar-link">成功案例</router-link>
        <a href="/#contact" class="top-bar-link">聯絡我們</a>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    showTopBar: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    this.isMobile = window.innerWidth <= 768;
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
    },
    beforeEnter(el) {
      el.style.opacity = 0;
    },
    enter(el, done) {
      const delay = el.dataset.index * 150;
      setTimeout(() => {
        el.style.opacity = 1;
        done();
      }, delay);
    },
    leave(el, done) {
      el.style.opacity = 0;
      done();
    },

  },
  beforeEnter(el) {
    el.style.opacity = 0;
    el.style.transform = 'translateY(20px)';
  },
  enter(el, done) {
    el.offsetHeight; // trigger reflow
    el.style.transition = 'opacity 0.5s ease, transform 0.5s ease';
    el.style.opacity = 1;
    el.style.transform = 'translateY(0)';
    done();
  },
  leave(el, done) {
    el.style.transition = 'opacity 0.5s ease, transform 0.5s ease';
    el.style.opacity = 0;
    el.style.transform = 'translateY(20px)';
    done();
  }
};
</script>

<style scoped>
.top-bar-logo {
  height: 40px; /* 根据实际logo的高度调整 */
  height: 60px;
}

/* Top Bar */
.top-bar {
  position: fixed;
  top: 0px;
  width: 100%;
  right: 0px;

  background-color: #00796b; /* 深色背景 */
  padding: 20px 0;
  transition: background-color 0.3s, box-shadow 0.3s; /* 添加过渡效果 */
  z-index: 1000;
}
.top-bar-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.top-bar-link {
  color: #fff;
  text-decoration: none;
  font-size: 20px;
  transition: color 0.3s; /* 添加过渡效果 */
}

section {

  color: #333;
  opacity: 0;
  transition: opacity 1s ease-out;

}



.overview-column:hover .overview-image {
  opacity: 0.8;
}



.service-icon img {
  width: 50px;
  height: 50px;
}



#approved-cases .container {
  margin: 0 auto;
  padding: 0 15px;
}

#approved-cases .english-title {
  font-size: 18px;
  color: #333;
  text-align: start;
  margin-bottom: 10px;
}

#approved-cases .section-title {
  font-size: 28px;
  text-align: start;
  margin-bottom: 40px;
}

#approved-cases .approved-cases-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Space between items */
  justify-content: center;
}

#approved-cases .case-item {
  flex: 1 1 calc(33.333% - 20px); /* 3 items per row with gap */
  max-width: calc(33.333% - 20px); /* Max width for each item */
  box-sizing: border-box;
}

#approved-cases .case-item img {
  width: 100%;
  height: auto;
  border-radius: 8px; /* Rounded corners for images */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

#approved-cases .case-item .case-description {
  text-align: center;
  font-size: 16px;
  color: #666;
  margin-top: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  #approved-cases .case-item {
    flex: 1 1 calc(50% - 20px); /* 2 items per row on smaller screens */
    max-width: calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  #approved-cases .case-item {
    flex: 1 1 100%; /* 1 item per row on very small screens */
    max-width: 100%;
  }
}



.case-item:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.top-bar:hover {
  background-color: #00796b; /* 悬停时背景颜色 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 悬停时阴影效果 */
}

.top-bar a {
  color: #ffffff;
  text-decoration: none;
  margin: 0 15px;
  position: relative;
  transition: color 0.3s ease;
}

.top-bar a:hover {
  color: #ffeb3b;
}

.top-bar .indicator {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 0;
  background-color: #ffeb3b;
  transition: width 0.3s ease;
}

.top-bar a:hover ~ .indicator {
  width: 100%;
}

section.visible {
  opacity: 1;
}

.text-overlay p {
  margin-bottom: 15px;
  line-height: 1.8;
  font-size: 16px;
}


@media (max-width: 768px) {


  .text-overlay p {
    font-size: 14px;
  }
}



.text-content h1 {
  font-size: 24px;
  color: #333;
  margin-bottom: 15px;
}

.text-content p {
  font-size: 18px;
  color: #555;
  line-height: 1.6;
  margin: 10px 0;
}



.text-content h2 {
  font-size: 1.5em;
  color: #00796b;
  margin-bottom: 10px;
  font-weight: bold;
}

.text-content p {
  font-size: 1em;
  line-height: 1.6;
}

footer {
  text-align: center;
  margin-top: 30px;
  font-size: 0.8em;
  color: #777;
}



@keyframes fadeInUp {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}



/* 气泡动画 */
@keyframes bubble {
  0% {
    transform: translateY(0) translateX(0) scale(1);
    opacity: 1;
  }
  50% {
    transform: translateY(-50vh) translateX(20px) scale(1.2);
    opacity: 0.7;
  }
  100% {
    transform: translateY(-100vh) translateX(-20px) scale(1);
    opacity: 0;
  }
}

.image-container img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.image-container img:hover {
  transform: scale(1.05);
}

h1 {
  font-size: 1.8em;
  color: #333;
  margin-bottom: 15px;
  text-align: center;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin-bottom: 10px;
}

p {
  text-align: justify;
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
  }

  h1 {
    font-size: 1.5em;
  }
}

</style>
