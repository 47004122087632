<template>
  <div :class="['container', { 'mobile': isMobile }]">
    <!-- 顶部标题 -->
    <header class="header">
      <div :class="['title-background', { 'mobile': isMobile }]">

        <header class="title-container">
          <h1 class="main-title">成功案例</h1>
          <p class="subtitle">发现更多学生成功申请的故事</p>
        </header>
        <!-- 增加装饰元素 -->
        <div class="decoration-top"></div>
        <div class="decoration-bottom"></div>
        <!-- 背景动画装饰 -->
        <div class="animated-background"></div>
      </div>
    </header>

    <!-- 主体内容 -->
    <div class="content">
      <!-- 主案例展示区域 -->
      <div class="main-case">
        <h2>{{ currentCase.title }}</h2>
        <div class="case-description" v-html="currentCase.description"></div>
        <div class="image-container">
          <img :src="currentCase.image" alt="案例图片" class="case-image" />
        </div>
      </div>

      <!-- 侧边栏案例列表 -->
      <aside class="sidebar">
        <h2>其他案例</h2>
        <ul>
          <li v-for="caseItem in otherCases" :key="caseItem.id" @click="selectCase(caseItem)">
            <img :src="caseItem.image" alt="缩略图" class="thumbnail" />
            <span>{{ caseItem.title }}</span>
          </li>
        </ul>
      </aside>
    </div>
  </div>
</template>

<script>
export default {
  name:"success-cases",
  data() {
    return {
      isMobile: false,
      // 当前展示的案例
      currentCase: {
        id: 1,
        title: "李*天的案例",
        image: "https://saas-2-ryx.oss-cn-guangzhou.aliyuncs.com/1522186891129004033/fdf0039a09bb421c989fac61328f5269.jpg",
        description: `
          <strong>背景信息：</strong>
          <p>李*天是壹名來自內地的學生，希望在香港接受優質教育。由于內地和香港教育體系的差異，李*天在學習節奏和規劃方面遇到了挑戰。家長對香港的教育環境不熟悉，因此尋求我們的幫助。</p>

          <strong>溝通過程：</strong>
          <ol>
            <li>首次咨詢中，我們詳細了解了李*天及其家長的需求和期望。通過評估發現，李*天在數學方面表現優秀，但英語基礎較弱。家長對選擇合適的學校和教育資源感到困惑。</li>
            <li>我們解釋了香港教育系統的特點，並幫助家長理解如何選擇適合李*天的學校。討論了李*天的學術背景和未來目標，以便制定最適合的教育方案。</li>
            <li>制定了個性化教育方案，包括學校推薦和英語提升計劃，並多次與家長討論以確保方案的合理性和家長的滿意度。</li>
          </ol>

          <strong>服務過程：</strong>
          <ul>
            <li><strong>學習評估：</strong> 我們對李*天進行了詳細的學術評估，記錄了數學（85分）和英語（60分）成績，並分析了他的學習習慣和興趣，識別出其優勢和改進空間。</li>
            <li><strong>學校推薦：</strong> 根據評估結果，推薦了幾所直資學校，這些學校在數學和英語教學方面都具有優勢。最終，李*天選擇了直資學校，因其小班教學和個性化輔導著稱。</li>
            <li><strong>英語提升計劃：</strong> 安排了每周兩次的外教輔導，重點提升口語和聽力能力。制定了詳細的練習計劃，並進行了多次面試模擬，幫助李*天適應英語環境。</li>
            <li><strong>申請支持：</strong> 協助准備申請材料，包括個人陳述、推薦信和學術成績單，並進行面試培訓，確保李*天能夠在面試中表現出色。</li>
            <li><strong>跟進服務：</strong> 在入學後，繼續跟蹤李*天的學習情況，與學校保持溝通，及時調整學習計劃，確保他能夠順利適應新環境並取得良好成績。</li>
          </ul>

          <strong>結果：</strong>
          <p>李*天成功入讀了直資學校。入學壹年後，數學成績提升至92分，英語成績提升至75分。他在數學競賽中獲得二等獎，並在英語口語方面顯著進步。他還積極參與科技社團，展現了卓越的潛力。</p>

          <strong>客戶反饋：</strong>
          <p>李*天的父母表示：“貴公司的專業指導和支持讓李*天的學業和個人能力得到了全面提升。特別是在英語學習和面試准備方面，幫助我們解決了很多實際問題。我們非常滿意，並對未來充滿信心。”</p>
        `
      },
      // 其他案例列表
      otherCases: [
        {
          id: 1,
          title: "李*天的案例",
          image: "https://saas-2-ryx.oss-cn-guangzhou.aliyuncs.com/1522186891129004033/fdf0039a09bb421c989fac61328f5269.jpg",
          description: `
          <strong>背景信息：</strong>
          <p>李*天是壹名來自內地的學生，希望在香港接受優質教育。由于內地和香港教育體系的差異，李*天在學習節奏和規劃方面遇到了挑戰。家長對香港的教育環境不熟悉，因此尋求我們的幫助。</p>

          <strong>溝通過程：</strong>
          <ol>
            <li>首次咨詢中，我們詳細了解了李*天及其家長的需求和期望。通過評估發現，李*天在數學方面表現優秀，但英語基礎較弱。家長對選擇合適的學校和教育資源感到困惑。</li>
            <li>我們解釋了香港教育系統的特點，並幫助家長理解如何選擇適合李*天的學校。討論了李*天的學術背景和未來目標，以便制定最適合的教育方案。</li>
            <li>制定了個性化教育方案，包括學校推薦和英語提升計劃，並多次與家長討論以確保方案的合理性和家長的滿意度。</li>
          </ol>

          <strong>服務過程：</strong>
          <ul>
            <li><strong>學習評估：</strong> 我們對李*天進行了詳細的學術評估，記錄了數學（85分）和英語（60分）成績，並分析了他的學習習慣和興趣，識別出其優勢和改進空間。</li>
            <li><strong>學校推薦：</strong> 根據評估結果，推薦了幾所直資學校，這些學校在數學和英語教學方面都具有優勢。最終，李*天選擇了直資學校，因其小班教學和個性化輔導著稱。</li>
            <li><strong>英語提升計劃：</strong> 安排了每周兩次的外教輔導，重點提升口語和聽力能力。制定了詳細的練習計劃，並進行了多次面試模擬，幫助李*天適應英語環境。</li>
            <li><strong>申請支持：</strong> 協助准備申請材料，包括個人陳述、推薦信和學術成績單，並進行面試培訓，確保李*天能夠在面試中表現出色。</li>
            <li><strong>跟進服務：</strong> 在入學後，繼續跟蹤李*天的學習情況，與學校保持溝通，及時調整學習計劃，確保他能夠順利適應新環境並取得良好成績。</li>
          </ul>

          <strong>結果：</strong>
          <p>李*天成功入讀了直資學校。入學壹年後，數學成績提升至92分，英語成績提升至75分。他在數學競賽中獲得二等獎，並在英語口語方面顯著進步。他還積極參與科技社團，展現了卓越的潛力。</p>

          <strong>客戶反饋：</strong>
          <p>李*天的父母表示：“貴公司的專業指導和支持讓李*天的學業和個人能力得到了全面提升。特別是在英語學習和面試准備方面，幫助我們解決了很多實際問題。我們非常滿意，並對未來充滿信心。”</p>
        `}
        ,
        {
          id: 2,
          title: "陳*偉的案例",
          image: "https://saas-2-ryx.oss-cn-guangzhou.aliyuncs.com/1522186891129004033/f41723aa11b94ac9b15f78f8cb2a07a5.jpg",
          description: `
          <strong>背景信息：</strong>
          <p>陳*偉是壹名新港人孩子，初到香港時對學校選擇感到困惑。她性格開朗，喜歡藝術，但學術表現壹般。她的家長希望找到壹所適合她的學校，以便她能夠全面發展，特別是在藝術方面。</p>

          <strong>溝通過程：</strong>
          <ol>
            <li>我們詳細了解了陳*偉及其家長的需求和期望。發現她在藝術方面有較強的興趣，但學術成績壹般。家長希望找到既能支持她的藝術興趣又能提升她學術表現的學校。</li>
            <li>在與家長的溝通中，我們解釋了香港教育體系的特點，並介紹了針對性學校的選擇標准。討論了陳*偉的學術和藝術發展需求，以制定合適的教育方案。</li>
            <li>爲陳*偉提供了個性化的學校推薦，並制定了詳細的輔導計劃，確保她能夠在藝術和學術兩方面得到全面支持。</li>
          </ol>

          <strong>服務過程：</strong>
          <ul>
            <li><strong>學習評估：</strong> 對陳*偉進行了全面的學術評估，確定了她在藝術和學術方面的需求和潛力。評估結果顯示她在藝術方面有明顯的優勢，但學術成績有待提高。</li>
            <li><strong>學校推薦：</strong> 根據評估結果，推薦了壹所注重藝術教育的資助中學，該校學術氛圍寬松，適合陳*偉的全面發展。</li>
            <li><strong>藝術輔導：</strong> 安排了專業藝術老師進行輔導，幫助她提升繪畫技巧。同時，爲陳*偉提供了針對學校入學考試的學科輔導，以確保她能順利入學。</li>
            <li><strong>申請支持：</strong> 協助准備申請材料，包括個人陳述、推薦信和學術成績單，並進行面試和筆試培訓，幫助她在申請過程中表現出色。</li>
            <li><strong>跟進服務：</strong> 在入學後，繼續關注陳*偉的學習情況，定期與學校溝通，及時調整輔導計劃，確保她能夠順利適應學校生活並取得良好成績。</li>
          </ul>

          <strong>結果：</strong>
          <p>陳*偉成功入讀了推薦的直資中學。她在學校的藝術活動中表現出色，並在區裏的藝術比賽中獲得獎項。學術成績逐步提高，個人能力得到了全面發展。她在學校的藝術項目中展現了創意和才華，並積極參與課外活動。</p>

          <strong>客戶反饋：</strong>
          <p>陳*偉的家長表示：“感謝貴公司的細致輔導，陳*偉在藝術和學術上都有了顯著進步。特別是在藝術方面，她在比賽中獲獎讓我們感到非常滿意。妳們的專業服務讓我們非常滿意，幫助她找到了最合適的學校。”</p>
        `
        },
        {
          id: 3,
          title: "黃*美的案例",
          image: "https://saas-2-ryx.oss-cn-guangzhou.aliyuncs.com/1522186891129004033/178caf5f95a84cf7a71c3d55f9d6c025.jpg",
          description: `
          <strong>背景信息：</strong>
          <p>黃*美是香港本地生，基礎相對薄弱，但她有強烈的進取心，希望能考入壹所更好的學校。她的家長希望找到壹個能夠提供個性化培養的學校，以幫助她提升學術成績和全面發展。</p>

          <strong>溝通過程：</strong>
          <ol>
            <li>我們與黃*美及其家長進行了深入溝通，詳細了解了她的學習狀況和未來目標。發現她希望通過提升學術成績來進入更好的學校。</li>
            <li>基于她的需求，我們制定了壹個綜合性的提升計劃，包括個性化輔導和面試培訓。</li>
            <li>我們介紹了適合她的學校，並提供了詳細的申請指導，以確保她能夠順利完成申請過程。</li>
          </ol>

          <strong>服務過程：</strong>
          <ul>
            <li><strong>學習評估：</strong> 對黃*美進行了全面的學科評估，識別出她的知識漏洞和提升空間，並根據評估結果制定了個性化輔導計劃。</li>
            <li><strong>學校推薦：</strong> 推薦了壹所注重個性化培養的私立學校，符合她的學術需求和發展目標。</li>
            <li><strong>學科輔導：</strong> 提供了全面的學科輔導，包括針對薄弱環節的補習，以及多次面試培訓，幫助她提高面試表現。</li>
            <li><strong>申請支持：</strong> 協助准備申請材料，並進行面試模擬，確保她能夠展示最佳狀態。</li>
            <li><strong>跟進服務：</strong> 在申請過程中，持續跟進她的進展，並提供額外的支持和建議，以解決可能遇到的問題。</li>
          </ul>

          <strong>結果：</strong>
          <p>黃*美成功入讀了推薦的私立學校。入學後，她的學術成績顯著提高，特別是在數學和科學方面表現突出。她積極參與學校的社團活動，並展示了領導能力和自信心。</p>

          <strong>客戶反饋：</strong>
          <p>黃*美的家長表示：“貴公司提供的學科輔導和面試培訓極大地幫助了黃*美。她的成績和自信心都有了很大提升，非常感謝妳們的支持。”</p>
        `
        },
        {
          id: 4,
          title: "張*婷的案例",
          image: "https://saas-2-ryx.oss-cn-guangzhou.aliyuncs.com/1522186891129004033/c46218eb9b7e42789239c7eebfd9c741.jpg",
          description: `
          <strong>背景信息：</strong>
          <p>張*婷是壹名來自內地的學生，希望在香港獲得更好的教育。她在數學和科學方面表現優秀，但英語成績較低。她的家長希望找到壹所能夠提供綜合性教育和英語強化的學校。</p>

          <strong>溝通過程：</strong>
          <ol>
            <li>我們詳細了解了張*婷的學習背景和目標，發現她在數學和科學方面表現出色，但英語成績不理想。</li>
            <li>與家長討論了她的需求，提供了關于香港教育體系的詳細信息，並介紹了符合她要求的學校和教育方案。</li>
            <li>制定了壹個綜合性的教育計劃，以提升她的英語能力並推薦適合的學校。</li>
          </ol>

          <strong>服務過程：</strong>
          <ul>
            <li><strong>學習評估：</strong> 對張*婷進行了詳細的學科評估，記錄了她的數學（90分）、科學成績和英語（55分）成績。確定了她在英語方面的提升空間。</li>
            <li><strong>學校推薦：</strong> 推薦了壹所綜合能力強的學校，該校注重數學和科學教育，同時提供英語強化課程。</li>
            <li><strong>英語提升計劃：</strong> 安排了每周三次的英語輔導課程，包括詞彙和語法訓練，並進行模擬面試。</li>
            <li><strong>申請支持：</strong> 協助准備申請材料，提供面試模擬，並確保她能夠在申請過程中展示最佳狀態。</li>
          </ul>

          <strong>結果：</strong>
          <p>張*婷成功入讀了推薦的學校。入學後，她的英語成績提高到了70分，數學和科學成績保持優秀。她在學校的科技項目中表現突出，並獲得了獎學金。</p>

          <strong>客戶反饋：</strong>
          <p>張*婷的家長表示：“貴公司的建議和輔導對張*婷的學業發展起到了關鍵作用。她在英語上的進步以及整體學術成績都讓我們感到非常滿意。”</p>
        `
        },
        {
          id: 5,
          title: "歐陽*良的案例",
          image: "https://saas-2-ryx.oss-cn-guangzhou.aliyuncs.com/1522186891129004033/e0d052532e0548f2adf1402e3a7a2e1e.jpg",
          description: `
          <strong>背景信息：</strong>
          <p>歐陽*良是壹名希望在香港接受國際教育的學生，他在內地的學術成績優秀，但對香港的教育體系不熟悉。家長希望找到壹個能夠提供國際視野的學校，以提升他的學術水平和全球視野。</p>

          <strong>溝通過程：</strong>
          <ol>
            <li>我們詳細了解了歐陽*良及其家長的需求，發現他們希望歐陽*良能夠進入壹所國際化的學校，以提升他的全球視野和學術能力。</li>
            <li>基于需求，我們提供了關于國際學校的詳細信息，並制定了壹個個性化的教育計劃。</li>
            <li>介紹了適合歐陽*良的學校，並協助制定申請材料和面試准備，以確保他的申請能夠順利通過。</li>
          </ol>

          <strong>服務過程：</strong>
          <ul>
            <li><strong>學習評估：</strong> 對歐陽*良進行了全面的學術評估，記錄了他的各科成績，並了解了他的國際教育需求。</li>
            <li><strong>學校推薦：</strong> 推薦了壹所注重國際化教育的學校，該校提供全球視野和多文化交流的課程。</li>
            <li><strong>國際化課程輔導：</strong> 安排了國際化課程輔導，幫助歐陽*良適應新的學習環境，並提供跨文化溝通培訓。</li>
            <li><strong>申請支持：</strong> 協助准備申請材料，包括個人陳述和學術成績單，並進行面試培訓，以展示他的國際化背景和學術能力。</li>
          </ul>

          <strong>結果：</strong>
          <p>歐陽*良成功入讀了推薦的公立學校。入學後，他不僅適應了國際化的學習環境，還在全球視野和跨文化交流方面表現出色。他在學校的國際項目中取得了優異的成績，並獲得了獎學金。</p>

          <strong>客戶反饋：</strong>
          <p>歐陽*良的家長表示：“貴公司提供的國際化教育輔導幫助歐陽*良成功適應了新的學習環境，並提升了他的全球視野。我們對妳們的服務非常滿意，感謝妳們的支持。”</p>
        `
        }


        // 添加其他案例數據
      ]

    };

  },
  mounted() {
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile);
  },
  methods: {
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    selectCase(caseItem) {
      this.currentCase = caseItem;
    }

  }
};
</script>
<style scoped >
/* 整体布局 */
.container {
  font-family: 'PingFang SC', 'Helvetica Neue', Arial, sans-serif;
  color: #333;
  max-width: 1200px;
  margin: 0 auto;

}

.container.mobile {
  /* 适用于手机端的样式 */
  font-family: 'PingFang SC', 'Helvetica Neue', Arial, sans-serif;
  color: #333;
}

/* 顶部标题设计 */
.header {
  text-align: center;
  margin-bottom: 40px;
  position: relative;
}

.title-background {
  background: linear-gradient(135deg, rgba(0, 121, 107, 0.85), rgba(72, 169, 153, 0.85)),
  url('https://saas-2-ryx.oss-cn-guangzhou.aliyuncs.com/1522186891129004033/6c87b509f93c419d9391a60a3f4a0589.jpg') no-repeat center/cover;
  padding: 40px 20px;
  border-radius: 10px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.25);
  text-align: center;
  position: relative;
  overflow: hidden;
  border: 2px solid rgba(255, 255, 255, 0.3); /* 增加边框 */
  margin-top: 120px;
}

.title-background.mobile {
  background: linear-gradient(135deg, rgba(0, 121, 107, 0.85), rgba(72, 169, 153, 0.85)),
  url('https://saas-2-ryx.oss-cn-guangzhou.aliyuncs.com/1522186891129004033/6c87b509f93c419d9391a60a3f4a0589.jpg') no-repeat center/cover;
  padding: 0px 10px;
  border-radius: 10px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.25);
  text-align: center;
  position: relative;
  overflow: hidden;
  border: 2px solid rgba(255, 255, 255, 0.3); /* 增加边框 */
  margin-top: 90px;

}

.title-container {
  position: relative;
  z-index: 2;
  animation: fadeInUp 1.5s ease-out;
}

.main-title {
  font-size: 3.8em;
  margin: 0;
  font-weight: bold;
  color: #fff;
  text-shadow: 3px 6px 8px rgba(0, 0, 0, 0.4);
  letter-spacing: 1.2px;
  animation: textZoom 1.8s ease-out;
}

.subtitle {
  font-size: 1.6em;
  color: #e0f7fa;
  margin-top: 20px;
  font-weight: 300;
  letter-spacing: 0.7px;
  animation: fadeIn 2s ease-out;
}

.decoration-top, .decoration-bottom {
  position: absolute;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.15);
  z-index: 1;
}

.decoration-top {
  width: 150px;
  height: 150px;
  top: -30px;
  left: 20px;
}

.decoration-bottom {
  width: 180px;
  height: 180px;
  bottom: -50px;
  right: 30px;
}

/* 主体内容布局 */
.content {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

/* 主案例展示 */
.main-case {
  flex: 4;
  background: #fff;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.main-case h2 {
  font-size: 2.2em;
  margin-bottom: 20px;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
  font-weight: 500;
  color: #00796b;
}

.case-description {
  font-size: 1.2em;
  line-height: 1.8;
  color: #555;
  text-align: justify;
  margin-bottom: 30px;
}

.case-description strong {
  font-weight: bold;
  color: #333;
}

.case-description ul {
  margin-top: 15px;
  padding-left: 20px;
}

.case-description ul li {
  margin-bottom: 10px;
}

/* 案例图片 */
.image-container {
  margin-top: 20px;
}

.case-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

/* 侧边栏 */
.sidebar {
  flex: 1;
  background: #fff;
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.sidebar h2 {
  font-size: 1.8em;
  color: #00796b; /* 主题色 */
  margin-bottom: 20px;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
  font-weight: 500;
}

/* 案例列表 */
.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.sidebar li:hover {
  transform: translateX(10px);
}

.sidebar li img.thumbnail {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  margin-right: 10px;
}

.sidebar li span {
  font-size: 1.2em;
  color: #00796b; /* 主题色 */
}

/* 响应式调整 */
@media (max-width: 768px) {
  .content {
    flex-direction: column;
  }

  .main-case h2, .sidebar h2 {
    font-size: 1.8em;
  }

  .decoration-top, .decoration-bottom {
    display: none; /* 移动端隐藏装饰元素，保持简洁 */
  }

  .main-title {
    font-size: 2.6em;
  }

  .subtitle {
    font-size: 1.3em;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes textZoom {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
}
/* 更小屏幕适配 */
@media only screen and (max-width: 480px) {
  .main-title {
    font-size: 1.8rem;
  }

  .subtitle {
    font-size: 0.9rem;
  }

  .case-description {
    font-size: 0.8rem;
  }

  .thumbnail {
    width: 40px;
    height: 40px;
  }
}
</style>