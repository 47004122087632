<template>
  <div id="app">
    <header>
      <nav>
        <!--  手机端  -->
        <TopBarMobile v-if="isMobile" />
        <!-- PC -->
        <TopBarPC v-if="!isMobile" />
      </nav>
    </header>

    <!-- 路由内容会显示在这里 -->
    <main>
      <router-view></router-view>
    </main>

    <!-- Footer -->
    <footer class="footer">
      <div class="container footer-content">
        <p class="footer-text">© 2024 香港中小學擇校服務. All rights reserved.</p>
      </div>
    </footer>
  </div>
</template>



<script>
import TopBarMobile from './components/isMobile/TopBarMobile.vue';
import TopBarPC from './components/pc/TopBarPC.vue';
export default {
  components:{
    TopBarMobile,
    TopBarPC
  },
  name: 'App',
  data() {
    return {
      isMobile:false
    };
  },
  created() {
    this.checkIfMobile();
  },
  beforeUnmount() {
  },
  mounted() {

  },
  methods: {
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 600;
    },

  }
};

</script>

<style scoped>

</style>




